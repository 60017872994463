<template>
  <div>
      <Product/>
  </div>
</template>

<script>
  import Product from './components/Product'
  export default {
    components: {
      Product,
    },
    data() {
      return {
      }
    },
  }
</script>
